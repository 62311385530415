.transaction__history-item {
    padding: 13px 16px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 12px;
    color: #fff;
    background-image: url("./assets/historyItem-darker-bg.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width:100%;
}



.transaction__history-item img {
    width:30px;    
}

.transaction__history-item-title {
    font-size: 24px;
    font-family: "Riffic", sans-serif;
    font-weight: 700;
    background: linear-gradient(180deg, #FED083 49.5%, #FFAE27 100%);
    -webkit-text-stroke: 2px #582B02;
    -webkit-background-clip: text;
    color: transparent;
    min-width: 127px;
}

.transaction__history-item-text {
    display:flex;
    flex-direction: column;
    gap: 4px;
}

.transaction__history-item-text span {
    font-family: 'Monsterrat', sans-serif;
    font-size: 12px;
}

.transaction__history-item-hash {
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:5px;
    background: #977970;
    padding: 6px;
    border-radius: 10px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
}