.klondike-button {
    width:100%;
    display:flex;
    flex-direction: row;
    gap: 5px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    border-radius:13px;

}

.klondike-button > span {
    font-family: 'Riffic', sans-serif;
    font-weight:700;
    font-size: 12px !important;
    text-transform: uppercase;
}

.klondike-button > img {
    width: 28px;
}

.enabled {
    &:active {
        transform: translateY(1px) scale(0.98);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
}

.wooden {
    background-image: url("./assets/Wood-texture.png");
    background-size: cover;
    border: 3px solid #6D3902;
    box-shadow: 
    0px -4px 0px 0px #0000001A inset,
    0px 5px 0px 0px #FFFFFF33 inset,
    0px 3px 0px 0px #00000066;
    color: white;
}

.gold {
    background: linear-gradient(
      181deg,
      rgba(254, 208, 131, 1),
      rgba(255, 174, 39, 1)
    );
    border: 3px solid #6D3902;
    box-shadow: 
    0px -4px 0px 0px #0000001A inset,
    0px 5px 0px 0px #FFFFFF33 inset,
    0px 3px 0px 0px #00000066;

    color: #582B02;
}

.sharp {
    background-image: url("./assets/sharp-texture.png");
    background-size:cover;
    border: 1.5px solid #582B02;
    box-shadow: 
    0px -4px 0px 0px #0000001A inset,
    0px 5px 0px 0px #FFFFFF33 inset,
    0px 15px 5px 0px #FED08373 inset,
    0px 3px 0px 0px #00000066;

    color: #582B02;
}

.silver {
    background-image: url("./assets/silver-texture.jpg");
    background-size:cover;
    background: linear-gradient(180deg, #B0CFFF 0%, #8C97AB 100%);
    border: 3px solid #494E5C;

    box-shadow: 
    0px 5px 1.4px 0px #32404B80 inset,
    0px 14px 1.4px 0px #C8E6FF33 inset,
    0px 3px 0px 0px #00000066;

    color: #582B02;
}

.bronze {
    background-image: url("./assets/bronze-texture.jpg");
    background-size:cover;
    
    background: linear-gradient(180deg, #D89A4D 49.5%, #A57032 100%);
    border: 3px solid #6D3902;

    box-shadow: 0px -4px 0px 0px #0000001A inset,
    0px 5px 0px 0px #FFFFFF33 inset,
    0px 15px 5px 0px #FED08373 inset,
    0px 3px 0px 0px #00000066;

    color: #582B02;

}