.swiper-button-prev,
.swiper-button-next {
  width: 37px;
  height: 37px;
  background-size: contain;
  background-repeat: no-repeat;
  &::after {
    font-size: 0;
  }
}

.swiper-button-prev {
    background-image: url("./assets/left.svg");
  }
  
  .swiper-button-next {
    background-image: url("./assets/right.svg");
  }



  .game-rules-slider__title {
    font-size: 24px;
    display: block;
    text-align: center;
    font-weight: 1000;
  }
  
  .game-rules-slider__text {
    font-size: 12px;
    font-family: "Riffic", sans-serif;
    margin: auto;
    width: 210px;
    text-align: center;
    margin: 12px auto 28px;
  }
  
  .game-rules-slider__img {
    display: block;
    margin: auto;
    width:200px;
    border-radius: 10px;
    border: 3px solid #582B02;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }