.bottom-nav {
  padding: 9px 0;
  flex-shrink: 0;
}

.bottom-nav--dark {
  background: #20130b;
}

.bottom-nav__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
