.bounty-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    padding: 12px 10px;
    height: 63px;
    /* gap: 12px; */

    background-image: url('./assets/Wood-texture-brownish.png');
    background-size: cover;
    border-radius: 13px;

    width: 100%;

    box-shadow: 0px -4px 0px 0px #0000001A inset, 0px 5px 0px 0px #FFFFFF33 inset,0px 3px 0px 0px #00000066;

}

.bounty-item__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: start;
    padding-left: 14px;
    padding-right: 14px;
    width:100%;
}

.bounty-item__title {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color:white;
}

.bounty-item__text {
    font-family: 'Riffic',sans-serif;
    font-size: 12px;
    font-weight: 700;
    color:white;
}

.bounty-item__reward {
    display:flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.bounty-item__reward > img{
    width: 15px;
}

.bounty-item__status-img {
    width:25px;
}

.bounty-item__content:nth-child(2) {
    border-left: 2px solid white;
    border-right: 2px solid white;
}

.waiting {
    border: 3px solid #DB8412
}

.accepted {
    border: 3px solid #0C680C
}

.canceled {
    border: 3px solid #8D270E
}