.notification {
  position: fixed;
  top: 6px;
  left: 0;
  right: 0;
  margin: auto;
  width: calc(100% - 12px);
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 16px 34px;
  color: #582b02;
  border-radius: 24px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: opacity 0.3s ease;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  z-index: 99;

  b {
    margin: 0 5px;
  }
}

.notification-hide {
  opacity: 0;
}

.notification__line {
  margin: 18px auto 0;
  width: 50%;
  display: block;
  background: #634228;
  height: 2px;
}
