.bounty-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Riffic', sans-serif;
    gap: 20px;
    margin-top: 20px;
    width:100%;
}

.bounty-page > img {
    width:90%;
}

.bounty-page > span {
    font-family: Riffic;
    font-size: 20px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-transform:uppercase

}

.bounty-page__cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
}

.bounty-page__btns {
    display: flex;
    flex-direction: column;
    gap:10px;
    justify-content: center;
    align-items: center;
    width:100%;
}

.bounty-page__form {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
    gap:15px;

}

.bounty-page__form__link {
    display: flex;
    flex-direction: row;
    gap:5px;
    justify-content: space-between;
    align-items: center;

    border-radius: 24px;
    padding: 6px 15px;

    background: var(--white, #FFFFFF);
    border: 1.5px solid #582B02;

    width:100%;
}

#link-img {
    width: 24px;
}

#copy-img {
    width: 34px;
    cursor: pointer;

    &:active {
        transform: translateY(1px) scale(0.98);
    }
}

#link-input {
    text-align:center;
    color:#582B02;

    font-family: 'Monsterrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;

    font-size: 13px;
}


.bounty-page__form__textarea {
    border-radius:24px;
    border: 1.5px solid #582B02;
    width:100%;
    height: 128px;
    resize: none;
    padding: 19px 15px;

    color:#582B02;

    font-family: 'Monsterrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;

    font-size: 13px;
}

.bounty-page__form__title {
    font-family: 'Riffic', sans-serif;
    font-weight: 700;
    color:#582B02;
    font-size:16px;
}  

.bounty-page__form__text {
    white-space: pre-wrap;
    font-family: 'Monsterrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.bounty-page__history {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
    justify-content: center;
    align-items: center;
}

.bounty-page__form__text-list {
    display:flex;
    flex-direction: column;
    gap:5px;
    width:100%;
}

.scrollable {
    max-height: 80%;
    overflow-y: auto;   
}
