.storyline {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  display: flex;
  gap: 40px;
  flex-direction: column;
}

.storyline__item {
  display: flex;
  align-items: center;
  gap: 22px;
  color: #fff;
  position: relative;
  z-index: 1;
}

.storyline__item:after {
  content: "";
  position: absolute;
  width: calc(100% - 60px);
  top: 82%;
  background-image: url("./assets/line.svg");
  background-repeat: no-repeat;
  left: 30px;
  height: 55px;
  background-size: 100% auto;
  z-index: -1;
}

.storyline__item:nth-child(even) {
  flex-direction: row-reverse;

  .storyline__img-block {
    position: relative;

    &::after {
      left: auto;
      right: -8px;
    }

    &::before {
      left: auto;
      right: -16px;
    }
  }
}

.storyline__item:nth-child(even)::after {
  background-image: url("./assets/line2.svg");
}

.storyline__item:last-child::after {
  display: none;
}

.storyline__title {
  font-size: 16px;
  color: #582b02;
  display: flex;
  align-items: center;
  gap: 10px;
}

.storyline__text {
  font-family: "Montserrat", sans-serif;
  margin-top: 4px;
}

.storyline__img {
  position: relative;
  z-index: 1;
}

.storyline__img-block {
  position: relative;

  &:after {
    content: "";

    width: 86px;
    height: 86px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: -8px;
    top: -9px;
  }

  &::before {
    content: "";

    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #fff;
    top: -16.6px;
    left: -16px;
    position: absolute;
  }
}
