.information-box {
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 14px;
  background-color: #fff;
  border: 3px solid #582b02;
  border-radius: 13px;
  padding: 12px 16px;
}
.information-box__img {
  max-width: 22px;
}
.information-box__block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.information-box__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.information-box__text {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.information-box__text {
  white-space: pre-wrap;
}