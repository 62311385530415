.big-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 20px 8px;
  background-image: url(./assets/img-bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 220px;
  min-height: 76px;

  &.connected {
    color: #fff;
    background-color: rgba(152, 152, 152, 1);
    border-radius: 6.5px;
    border: 2px solid rgba(255, 255, 255, 1);
    background-image: none;
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(200%);
    }
  }
}

.big-button__img {
  max-width: 36px;
}

.big-button__text {
  text-transform: uppercase;
  letter-spacing: 1.3px;
}
