.loading-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./assets/main.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.loading-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:20px;
}

.loading-img {
  max-width: 100%;
  height: auto;
}
