.bottom-nav__link {
  width:70px;
  height:70px;
  /* max-width: 89px;
  min-height: 70px;
  width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(./assets/bg-yelloy.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5px 7px;
  text-align: center;

  &.active {
    color: #fff;
    background-image: url(./assets/bg-white.png);
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(200%);
    }
  }
}

/* .bottom-nav__link; */

.bottom-nav__text{
  font-size: 10px;
  font-family: "Riffic", sans-serif;
}
