.shovel {
  padding: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  outline: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  z-index:1;
  
}


.shovel:focus {
  outline: none;
}

.shovel:focus-visible {
  outline: none;
}

.shovel__img {
  display: block;
  width: 100%;
  max-height: 90%;
  object-fit: contain;
  pointer-events: none;
}

.coin {
  position: absolute;
  width: 42px;
  height: 42px;
  background-image: url("./assets/img/coin.png");
  background-size: contain;
  background-repeat: no-repeat;
  animation: moveUp 5s ease-out forwards;
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  30%{
    opacity: 1;
  }
  100% {
    transform: translateY(-400px);
    opacity: 0;
  }
}
