.popup {
  z-index: 4;
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: calc(100% - 32px);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./assets/popup-img__bg.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.popup-content {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-open {
  bottom: -20px;
}

.popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
}

.popup__img {
  max-width: 129px;
  border-radius: 16%;
}

.popup__text {
  font-size: 48px;
  margin: 12px 0;
}

.popup__block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
