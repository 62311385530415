.referral__farming {
    display:flex;
    width:100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.referral__farming-block {
    display:flex;
    width:100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: row;
}

.referral__farming-coins-img {
    width: 40px;
}

.referral__farming-descr {
    font-size: 14px;
    color: white;
    align-self: start;
}

.referral__farming-coins-text {
    font-size: 32px;
    font-weight:400;
    background: linear-gradient(180deg, #FED083 49.5%, #FFAE27 100%);
    -webkit-text-stroke: 2px #582B02;
    -webkit-background-clip: text;
    color: transparent;
}

.referral__farming-coins {
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

