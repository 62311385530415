.bounty-card {
    display: flex;
    flex-direction: column;
    gap:5px;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 96px;

    background-image: url("./assets/Wood-texture.png");
    background-size: cover;

    border-radius:24px;
    border: 2px solid #6D3902;

    box-shadow: 
    0px -5px 0px 0px #0000001A inset,
    0px 5px 0px 1px #FFFFFF33 inset,
    0px 3px 0px 0px #00000066;

}

.bounty-card__img {
    width:40px;
}

.bounty-card > span {
    font-size:14px;
    font-weight:700;
    font-family: 'Monsterrat',sans-serif;
    color: white;
    text-align: center;
}