.competition {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:15px;

    margin-top: 20px;
    width:100%;
}

.competition__image-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:-15px;
    width:100%;
}

.competition__image-block > img {
    width:267px;
}

.competition__image-block-prize {
    width:100%;
    height:74px;
    background-color: #582B02;

    border-radius:24px;

    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10px;

    box-shadow: 0px -12px 1px 0px #00000040 inset,0px 12px 1px 0px #00000040 inset;

    margin-top: -25px;

}

.competition__image-block-prize > img {
    width:54px;
}

.competition__image-block-prize > span {
    font-family: 'Riffic', sans-serif;
    font-size: 48px;
    font-weight: 700;

    color: #fdbf4c;
    background: linear-gradient(90deg, rgba(254, 208, 131, 1), rgba(255, 174, 39, 1));
    -webkit-background-clip: text;
    background-clip: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.competition__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10px;

    width:100%;
}


.modal-content-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
}

.modal-content-inner__list {
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    gap:10px;
}

.modal-content-inner__list > img {
    width:27px;
}

.modal-content-inner__list > span {
    font-family: 'Monsterrat', sans-serif;
    font-size:16px;
    color: #582B02;
}

.modal-content-inner__title{
    font-family: 'Riffic', sans-serif;
    font-size:24px;
    color: #582B02;

}