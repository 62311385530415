.competition__leaderboard {
    width:100%;
    
    display:flex;
    flex-direction: column;
    gap: 10px;

    background: #00000040;
    box-shadow: 
    0px 4px 4px 0px #00000040 inset,
    0px -12px 1px 0px #00000040 inset,
    0px 12px 1px 0px #00000040 inset;

    border-radius:24px;

    padding: 25px 15px;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.competition__leaderboard-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap:10px;
    /* padding: 0 20px; */
    /* height: 100%; */

    font-family: 'Riffic', sans-serif;
}

.title-row {
    color: white;
}

.title-row > span {
    font-size: 16px;
    font-weight: 700;

    text-shadow: 0px 2px 2px #00000066

}

.place, .user, .invites, .reward {
    height: 41px;

}

.place {
    width: 18%;
}

.user {
  width:40%;
}

.invites {
    width: 25%;
}

.reward {
    width: 20%
}

.place > img {
    width: 19px;
}

.reward > img {
    width: 19px;
}

.red-line {
    border: 3px solid #B22E2E
}