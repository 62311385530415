.gift {
    width: 40%;
    position: absolute;
    z-index: 2;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
}

.glowing {
    animation: glow 0.55s ease-in-out 1;
}

@keyframes shake-and-stop {
    0% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(-5deg);
    }
    10% {
      transform: rotate(5deg);
    }
    15% {
      transform: rotate(-5deg);
    }
    20% {
      transform: rotate(5deg);
    }
    25% {
        transform: rotate(-5deg);
    }
    30% {
        transform: rotate(5deg);
    }
    35% {
        transform: rotate(-5deg);
      }
    40% {
        transform: rotate(5deg);
      }
    45% {
        transform: rotate(-5deg);
      }
    50% {
      transform: rotate(0deg);
    }
    50.1% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes glow {
    0% {
      filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
      opacity: 0.5;
    }
    25% {
      filter: drop-shadow(0 0 40px rgba(255, 255, 255, 1));
      opacity: 0.8;
    }
    50% {
      filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
      opacity: 0.5;
    }
    75% {
      filter: drop-shadow(0 0 40px rgba(255, 255, 255, 1));
      opacity: 0.8;
    }
    100% {
      filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
      opacity: 0.5;
    }
  }
  
  .shaking {
    animation: shake-and-stop 2s ease-in-out infinite;
  }